// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Icon__Jsx3 = require("../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var MediaQuery = require("../common/Media/MediaQuery.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CurrentUser = require("../common/CurrentUser/CurrentUser.bs.js");
var Next__Atoms = require("../next/Next__Atoms.bs.js");
var ClickOutside = require("../common/ClickOutside.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Dialog__Jsx3 = require("../../uikit/reason/molecules/Dialog/Dialog__Jsx3.bs.js");
var Buttons__Jsx3 = require("../../uikit/reason/atoms/Buttons/Buttons__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var ClientBuyingSvg = require("./client-buying.svg");
var ClientSellingSvg = require("./client-selling.svg");
var BroadcastMegaphoneSvg = require("./broadcast-megaphone.svg");

var clientBuying = ClientBuyingSvg;

var clientSelling = ClientSellingSvg;

var broadcastMegaphone = BroadcastMegaphoneSvg;

function DashboardHero$List(props) {
  return JsxRuntime.jsx("ul", {
              children: props.items.map(function (x) {
                    return JsxRuntime.jsxs("li", {
                                children: [
                                  JsxRuntime.jsx(Icon__Jsx3.make, {
                                        icon: "OkCircledAlt",
                                        className: "mt-[2px]",
                                        size: 20
                                      }),
                                  x
                                ],
                                className: "flex gap-2 font-medium items-center"
                              }, x);
                  }),
              className: "text-gray-600 flex flex-col gap-2"
            });
}

var List = {
  make: DashboardHero$List
};

function DashboardHero$ExpandedState$ActiveIcon(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("img", {
                    className: "h-14 md:h-16",
                    alt: "",
                    src: props.src
                  }),
              className: "flex justify-center w-1/2  \n        p-6 md:p-7 lg:p-10 bg-white rounded-t-lg\n        z-10"
            });
}

var ActiveIcon = {
  make: DashboardHero$ExpandedState$ActiveIcon
};

function DashboardHero$ExpandedState$InactiveIcon(props) {
  var onClick = props.onClick;
  return JsxRuntime.jsxs("button", {
              children: [
                JsxRuntime.jsx("img", {
                      className: "h-14 md:h-16",
                      alt: "",
                      src: props.src
                    }),
                JsxRuntime.jsx("div", {
                      className: "absolute bottom-0 left-0 w-full h-9 bg-gradient-to-t from-[rgba(0,0,0,0.075)] to-[rgba(0,0,0,0)]"
                    })
              ],
              className: "flex justify-center w-1/2 \n        p-6 md:p-7 lg:p-10 \n        bg-white relative opacity-50 rounded-t-lg",
              onClick: (function (param) {
                  Curry._1(onClick, undefined);
                })
            });
}

var InactiveIcon = {
  make: DashboardHero$ExpandedState$InactiveIcon
};

function DashboardHero$ExpandedState$ExpandedContent(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Next__Atoms.PrimaryButton.make, {
                            href: props.link,
                            size: "large",
                            children: Caml_option.some(props.cta)
                          })
                    }),
                JsxRuntime.jsx("h4", {
                      children: props.title,
                      className: "mt-5 mb-0 text-center text-base leading-5 text-gray-700 font-medium"
                    }),
                JsxRuntime.jsx(DashboardHero$List, {
                      items: props.listItems
                    })
              ],
              className: "flex flex-col gap-5 items-center w-1/2 \n          py-8 px-12 md:py-9 md:pr-10 md:pl-y lg:pr-16 pl-10\n        "
            });
}

var ExpandedContent = {
  make: DashboardHero$ExpandedState$ExpandedContent
};

function DashboardHero$ExpandedState$ExpandedBuyerContent(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardHero$ExpandedState$ExpandedContent, {
                      link: "/private_buyers/new",
                      title: "Connect only with agents who have a matching seller, without emailing your TAN chapter.",
                      listItems: [
                        "Common buyer needs",
                        "Buyers early in the home hunt",
                        "Buyers who have NOT exhausted the MLS"
                      ],
                      cta: "Add buyer quietly"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            className: "w-full my-8 bg-gray-200"
                          }),
                      className: "flex flex-row w-px bg-white"
                    }),
                JsxRuntime.jsx(DashboardHero$ExpandedState$ExpandedContent, {
                      link: "/client_needs/new",
                      title: "Email a buyer or rental need to your entire TAN network. You'll also get matches.",
                      listItems: [
                        "Unique needs",
                        "Motivated, qualified and realistic",
                        "Already seen what's on the MLS"
                      ],
                      cta: "Broadcast a buyer or rental need"
                    })
              ],
              className: "flex flex-row"
            });
}

var ExpandedBuyerContent = {
  make: DashboardHero$ExpandedState$ExpandedBuyerContent
};

function DashboardHero$ExpandedState$ExpandedSellerContent(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardHero$ExpandedState$ExpandedContent, {
                      link: "/sellers/new",
                      title: "Find or connect with only agents who have a matching buyer, without emailing your TAN chapter.",
                      listItems: [
                        "Too early to market a property (no photos, poor condition, etc.)",
                        "Quietly gauge buyer demand and win listings",
                        "Seller demands extra privacy"
                      ],
                      cta: "Add seller quietly"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            className: "w-full my-8 bg-gray-200"
                          }),
                      className: "flex flex-row w-px bg-white"
                    }),
                JsxRuntime.jsx(DashboardHero$ExpandedState$ExpandedContent, {
                      link: "/properties/new",
                      title: "Email a property or rental to your entire TAN network. You'll also get matches.",
                      listItems: [
                        "Reach all local top agents",
                        "Test pricing"
                      ],
                      cta: "Broadcast a property"
                    })
              ],
              className: "flex flex-row"
            });
}

var ExpandedSellerContent = {
  make: DashboardHero$ExpandedState$ExpandedSellerContent
};

function DashboardHero$ExpandedState(props) {
  var setClientType = props.setClientType;
  var clientType = props.clientType;
  var tmp;
  tmp = clientType === "Seller" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(DashboardHero$ExpandedState$InactiveIcon, {
                  src: clientBuying,
                  onClick: (function (param) {
                      Curry._1(setClientType, "Buyer");
                    })
                }),
            JsxRuntime.jsx(DashboardHero$ExpandedState$ActiveIcon, {
                  src: clientSelling
                })
          ]
        }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(DashboardHero$ExpandedState$ActiveIcon, {
                  src: clientBuying
                }),
            JsxRuntime.jsx(DashboardHero$ExpandedState$InactiveIcon, {
                  src: clientSelling,
                  onClick: (function (param) {
                      Curry._1(setClientType, "Seller");
                    })
                })
          ]
        });
  var tmp$1;
  tmp$1 = clientType === "Seller" ? JsxRuntime.jsx(DashboardHero$ExpandedState$ExpandedSellerContent, {}) : JsxRuntime.jsx(DashboardHero$ExpandedState$ExpandedBuyerContent, {});
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(ClickOutside.make, {
                    onClickOutside: (function (param) {
                        Curry._1(setClientType, undefined);
                      }),
                    children: [
                      JsxRuntime.jsx("div", {
                            children: tmp,
                            className: "w-full flex gap-4"
                          }),
                      JsxRuntime.jsx("div", {
                            children: tmp$1,
                            className: "w-full flex bg-white z-10"
                          })
                    ]
                  }),
              className: "w-full flex flex-col items-center rounded-lg"
            });
}

var ExpandedState = {
  ActiveIcon: ActiveIcon,
  InactiveIcon: InactiveIcon,
  ExpandedContent: ExpandedContent,
  ExpandedBuyerContent: ExpandedBuyerContent,
  ExpandedSellerContent: ExpandedSellerContent,
  make: DashboardHero$ExpandedState
};

function DashboardHero$ClosedSection(props) {
  var cta = props.cta;
  var onClick = props.onClick;
  return JsxRuntime.jsxs("button", {
              children: [
                JsxRuntime.jsx("img", {
                      className: "h-14 md:h-16",
                      alt: "",
                      src: props.image
                    }),
                JsxRuntime.jsx("h4", {
                      children: cta,
                      className: "mt-5 text-2xl block md:hidden mb-0 text-gray-800"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Buttons__Jsx3.PrimaryButton.make, {
                              onClick: onClick,
                              size: "huge",
                              className: "px-7",
                              children: Caml_option.some(cta)
                            }),
                        JsxRuntime.jsx(DashboardHero$List, {
                              items: props.listItems
                            })
                      ],
                      className: "mt-7 hidden md:flex flex-col gap-7"
                    })
              ],
              className: "flex flex-col items-center p-6 md:p-7 lg:p-11 w-full sm:w-full md:w-1/2 bg-white rounded-lg hover:shadow-md transition-shadow",
              onClick: onClick
            });
}

var ClosedSection = {
  make: DashboardHero$ClosedSection
};

function DashboardHero$MobileModals$ModalContent(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("img", {
                      className: "h-14 md:h-16",
                      alt: "",
                      src: props.img
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h4", {
                              children: props.heading,
                              className: "text-2xl font-medium text-gray-800"
                            }),
                        JsxRuntime.jsx("div", {
                              children: props.sections.map(function (param) {
                                    return JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Next__Atoms.PrimaryButton.make, {
                                                        href: param.link,
                                                        size: "large",
                                                        children: Caml_option.some(param.cta)
                                                      }),
                                                  JsxRuntime.jsx("div", {
                                                        children: param.text,
                                                        className: "text-center w-11/12 font-medium text-gray-700"
                                                      })
                                                ],
                                                className: "flex flex-col items-center gap-5 pt-6"
                                              });
                                  }),
                              className: "flex flex-col items-center gap-6 divide-y"
                            })
                      ],
                      className: "flex flex-col items-center"
                    })
              ],
              className: "flex flex-col items-center gap-5"
            });
}

var ModalContent = {
  make: DashboardHero$MobileModals$ModalContent
};

function DashboardHero$MobileModals$BuyerModalContent(props) {
  return JsxRuntime.jsx(DashboardHero$MobileModals$ModalContent, {
              heading: "My client needs to buy or rent",
              img: clientBuying,
              sections: [
                {
                  cta: "Add buyer quietly",
                  text: "Connect only with agents who have a matching seller, without emailing your TAN chapter.",
                  link: "/private_buyers/new"
                },
                {
                  cta: "Broadcast a buyer or rental need",
                  text: "Email a buyer or rental need to your entire TAN network. You'll also get matches.",
                  link: "client_needs/new"
                }
              ]
            });
}

var BuyerModalContent = {
  make: DashboardHero$MobileModals$BuyerModalContent
};

function DashboardHero$MobileModals$SellerModalContent(props) {
  return JsxRuntime.jsx(DashboardHero$MobileModals$ModalContent, {
              heading: "My client is selling or renting",
              img: clientSelling,
              sections: [
                {
                  cta: "Add seller quietly",
                  text: "Find or connect with only agents who have a matching buyer, without emailing your TAN chapter.",
                  link: "/sellers/new"
                },
                {
                  cta: "Broadcast a property",
                  text: "Email a property or rental to your entire TAN network. You'll also get matches.",
                  link: "/properties/new"
                }
              ]
            });
}

var SellerModalContent = {
  make: DashboardHero$MobileModals$SellerModalContent
};

var MobileModals = {
  ModalContent: ModalContent,
  BuyerModalContent: BuyerModalContent,
  SellerModalContent: SellerModalContent
};

function DashboardHero$BroadcastMessageSection(props) {
  return JsxRuntime.jsxs(Next__Atoms.Link.make, {
              href: "/messages/new",
              className: "\n    flex flex-row bg-white transition-shadow hover:shadow-md \n    max-w-[768px] p-6 rounded-lg w-full lg:w-2/3 xl:w-1/2\n  ",
              children: [
                JsxRuntime.jsx("img", {
                      className: "flex-shrink-0 mr-6 mt-[5px] h-14 md:h-16",
                      alt: "",
                      src: broadcastMegaphone
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: "Broadcast a message",
                              className: "leading-none mb-2 text-2xl font-normal text-gray-800"
                            }),
                        JsxRuntime.jsx("span", {
                              children: "Start here to connect about service provider recommendations, referrals to agents in\n      other markets, industry discussion and other topics.",
                              className: "text-base text-gray-600"
                            })
                      ]
                    })
              ]
            });
}

var BroadcastMessageSection = {
  make: DashboardHero$BroadcastMessageSection
};

function DashboardHero$Hero(props) {
  var isMobile = MediaQuery.isMobile();
  var match = React.useState(function (param) {
        
      });
  var setClientType = match[1];
  var clientType = match[0];
  var tmp;
  tmp = clientType !== undefined ? (
      clientType === "Seller" ? JsxRuntime.jsx(DashboardHero$MobileModals$SellerModalContent, {}) : JsxRuntime.jsx(DashboardHero$MobileModals$BuyerModalContent, {})
    ) : null;
  var tmp$1;
  var exit = 0;
  if (clientType !== undefined && !isMobile) {
    tmp$1 = JsxRuntime.jsx(DashboardHero$ExpandedState, {
          clientType: clientType,
          setClientType: (function (c) {
              Curry._1(setClientType, (function (param) {
                      return c;
                    }));
            })
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$1 = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(DashboardHero$ClosedSection, {
                  onClick: (function (e) {
                      e.stopPropagation();
                      Curry._1(setClientType, (function (param) {
                              return "Buyer";
                            }));
                    }),
                  image: clientBuying,
                  listItems: [
                    "Broadcast a buyer or rental need",
                    "Add a buyer quietly"
                  ],
                  cta: "My client needs to buy or rent"
                }),
            JsxRuntime.jsx(DashboardHero$ClosedSection, {
                  onClick: (function (e) {
                      e.stopPropagation();
                      Curry._1(setClientType, (function (param) {
                              return "Seller";
                            }));
                    }),
                  image: clientSelling,
                  listItems: [
                    "Broadcast a property or available rental",
                    "Add a seller quietly"
                  ],
                  cta: "My client is selling or renting"
                })
          ],
          className: "flex flex-col gap-4 md:flex-row"
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Dialog__Jsx3.make, {
                      isOpen: Core__Option.isSome(clientType) && isMobile,
                      ariaLabel: "",
                      onDismiss: (function (param) {
                          Curry._1(setClientType, (function (param) {
                                  
                                }));
                        }),
                      children: tmp
                    }),
                JsxRuntime.jsx("div", {
                      children: tmp$1,
                      className: "w-full max-w-4xl m-0 mx-auto"
                    }),
                Core__Option.isNone(clientType) || Core__Option.isSome(clientType) && isMobile ? JsxRuntime.jsx(DashboardHero$BroadcastMessageSection, {}) : null
              ],
              className: "flex flex-col items-center gap-4 xl:gap-10"
            });
}

var Hero = {
  make: DashboardHero$Hero
};

function DashboardHero(props) {
  var user = CurrentUser.useCurrentUser();
  var areaName = Core__Option.getOr(Core__Option.flatMap(user.chapter, (function (a) {
              return a.displayName;
            })), "your area");
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h1", {
                      children: "Connect with " + areaName + "'s top 10% agents",
                      className: "text-center text-2xl md:text-4xl lg:text-6xl text-gray-800 font-light"
                    }),
                JsxRuntime.jsx(DashboardHero$Hero, {})
              ],
              className: "bg-gray-200 p-4 md:p-8 lg:p-10 gap-8 lg:gap-10 flex flex-col items-stretch w-full"
            });
}

var make = DashboardHero;

exports.clientBuying = clientBuying;
exports.clientSelling = clientSelling;
exports.broadcastMegaphone = broadcastMegaphone;
exports.List = List;
exports.ExpandedState = ExpandedState;
exports.ClosedSection = ClosedSection;
exports.MobileModals = MobileModals;
exports.BroadcastMessageSection = BroadcastMessageSection;
exports.Hero = Hero;
exports.make = make;
/* clientBuying Not a pure module */
